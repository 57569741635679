import axios from 'axios';
import * as config from './config';

export const signUp = (data: any) => {
  return axios.post(`${config.BASE_URL}/${config.SIGN_UP_URL}`, data, {
    withCredentials: false
  });
};

export const inViteUser = (data: any, token: string) => {
  return axios.post(`${config.BASE_URL}/${config.INVITE_USER_URL}`, data, {
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: false
  });
};

export const activityLogin = (data: any, token: string) => {
  return axios.post(`${config.BASE_URL}/${config.ACTIVITY_LOGIN_URL}`, data, {
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: false
  });
};

export const downloadFileCsv = (token: string, fileId: string) => {
  return axios.post(`${config.FILE_CSV_DOWNLOAD(fileId)}`, {}, {
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: false,
    responseType: "blob",
  });
};

export const downloadFileExcel = (token: string, fileId: string) => {
  return axios.post(`${config.FILE_EXCEL_DOWNLOAD(fileId)}`, {}, {
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: false,
    responseType: "blob",
  });
};
